import React from "react";
import styles from "./style.module.scss";

export default function GetConsultation() {
  return (
    <div className={styles.main}>
      <p className={styles.title}>ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ</p>
      <p className={styles.subTitle}>
        Отправьте заявку. С вами свяжется менеджер и поможет выбрать оптимальный
        вариант
      </p>
      <form>
        <input name="name" placeholder="Имя" type="text" />
        <input
          name="phone"
          placeholder="Телефон"
          type="phone"
          inputMode="numeric"
        />
        <input name="email" placeholder="Email" type="email" />
        <span className={styles.btn}>Отправить заявку</span>
      </form>
    </div>
  );
}
