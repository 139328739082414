import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollToTop = () => {
  // const { params } = useParams();
  const { pathname } = useLocation(); //search

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default useScrollToTop;
