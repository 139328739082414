import React, { useState } from "react";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

export default function CategoryItem({ data }) {
  const [hoverAnimation, setHoverAnimation] = useState(false);
  // console.log("hoverAnimation", hoverAnimation);
  return (
    <Link
      to={`catalog?dir=${data.value}`}
      className={`${styles.main} ${
        hoverAnimation ? styles.animationActive : ""
      }`}
      onMouseEnter={() => setHoverAnimation(true)}
      onAnimationEnd={() => setHoverAnimation(false)}
    >
      <div className={styles.content}>
        <h2>{data?.title}</h2>
        <div>
          <span>{data?.description}</span>
          <br />
          <span>Каждой детали</span>
        </div>
      </div>
      <div className={styles.imgBlock}>
        <img src={data.img} />
      </div>
    </Link>
  );
}
