import React from "react";
import styles from "./style.module.scss";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <footer className={styles.main}>
      <div className={styles.footerTop}>
        <div className="wrapper">
          <nav>
            <div>
              <NavLink to={"catalog?dir=gates"}>
                <span>Ворота и калитки</span>
              </NavLink>
              <NavLink to={"catalog?dir=railings"}>
                <span>Лестницы и перила</span>
              </NavLink>
              <NavLink to={"catalog?dir=visors"}>
                <span>Козырьки</span>
              </NavLink>
              <NavLink to={"catalog?dir=awnings"}>
                <span>Навесы</span>
              </NavLink>
              <NavLink to={"catalog?dir=balconies"}>
                <span>Кованые балконы</span>
              </NavLink>
            </div>
            <div>
              <NavLink to={"catalog?dir=swing"}>
                <span>Качели</span>
              </NavLink>
              <NavLink to={"catalog?dir=gazebos"}>
                <span>Беседки</span>
              </NavLink>
              <NavLink to={"catalog?dir=barbecues"}>
                <span>Мангалы</span>
              </NavLink>
              <NavLink to={"catalog?dir=interior"}>
                <span>Предметы интерьера</span>
              </NavLink>
              <NavLink to={"catalog?dir=fences"}>
                <span>Ограды и ограждения</span>
              </NavLink>
            </div>
            <div>
              <NavLink to={"/contacts"}>
                <span>Контакты</span>
              </NavLink>
              <NavLink to={"/aboutUs"}>
                <span>О компании</span>
              </NavLink>
            </div>
            <div>
              <a href="tel:89309419397">
                <span>8(930) 941-93-97</span>
              </a>
              <a href="tel:84957828190">
                <span>8(495) 782-81-90</span>
              </a>
              <a href="tel:89637129406">
                <span>8(963) 712-94-06</span>
              </a>
              <a href="mailto:armen6828@mail.ru">
                <span>armen6828@mail.ru</span>
              </a>
              <div className={styles.socBtns}>
                <div>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/arm_kovka.ru/"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_151_862)">
                        <path
                          d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M22 4H10C6.68629 4 4 6.68629 4 10V22C4 25.3137 6.68629 28 10 28H22C25.3137 28 28 25.3137 28 22V10C28 6.68629 25.3137 4 22 4Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          className={styles.fillInHover}
                          d="M22.5 11C23.3284 11 24 10.3284 24 9.5C24 8.67157 23.3284 8 22.5 8C21.6716 8 21 8.67157 21 9.5C21 10.3284 21.6716 11 22.5 11Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_151_862">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
                <div>
                  <a target="_blank" href="https://wa.me/79309419397">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_151_867)">
                        <path
                          d="M9 13C9 11.9391 9.42143 10.9217 10.1716 10.1716C10.9217 9.42143 11.9391 9 13 9L15 13L13.46 15.3088C14.0709 16.7684 15.2316 17.9291 16.6912 18.54L19 17L23 19C23 20.0609 22.5786 21.0783 21.8284 21.8284C21.0783 22.5786 20.0609 23 19 23C16.3478 23 13.8043 21.9464 11.9289 20.0711C10.0536 18.1957 9 15.6522 9 13Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.99133 26.3887C12.512 27.8475 15.4772 28.3399 18.3341 27.774C21.1909 27.2081 23.7445 25.6225 25.5187 23.3129C27.2929 21.0032 28.1665 18.1272 27.9768 15.221C27.7871 12.3148 26.5469 9.57679 24.4876 7.51742C22.4282 5.45805 19.6902 4.2179 16.784 4.02819C13.8778 3.83847 11.0018 4.71215 8.69214 6.48632C6.38252 8.26049 4.79694 10.8141 4.23103 13.6709C3.66511 16.5278 4.15748 19.493 5.61633 22.0137L4.05258 26.6824C3.99382 26.8586 3.9853 27.0477 4.02796 27.2285C4.07062 27.4092 4.16278 27.5745 4.29411 27.7059C4.42545 27.8372 4.59077 27.9294 4.77154 27.972C4.95231 28.0147 5.14139 28.0062 5.31758 27.9474L9.99133 26.3887Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_151_867">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
                <div>
                  <a target="_blank" href="https://t.me/ArmKovka">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_151_871)">
                        <path
                          d="M10 16.8588L21.2825 26.7501C21.4125 26.8647 21.57 26.9437 21.7397 26.9791C21.9093 27.0145 22.0853 27.0052 22.2502 26.9522C22.4152 26.8991 22.5636 26.8041 22.6808 26.6764C22.798 26.5487 22.88 26.3928 22.9187 26.2238L28 4.15258C28.005 4.13045 28.0038 4.10738 27.9967 4.08586C27.9895 4.06433 27.9766 4.04516 27.9594 4.03039C27.9422 4.01562 27.9213 4.00581 27.899 4.00201C27.8766 3.99821 27.8536 4.00057 27.8325 4.00883L2.5 13.9226C2.34274 13.9831 2.20935 14.093 2.11986 14.2358C2.03038 14.3786 1.98962 14.5465 2.0037 14.7145C2.01779 14.8824 2.08596 15.0412 2.19799 15.1671C2.31002 15.293 2.45985 15.3791 2.625 15.4126L10 16.8588Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10 16.8587L27.9263 4.01123"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.5463 21.7224L11.72 25.6924C11.5817 25.8358 11.4038 25.9347 11.209 25.9763C11.0141 26.018 10.8113 26.0005 10.6265 25.9261C10.4417 25.8517 10.2833 25.7237 10.1717 25.5587C10.0601 25.3937 10.0003 25.1991 10 24.9999V16.8586"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_151_871">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <span>© 2024 Компания "Арм-ковка". Все права защaищены.</span>
      </div>
    </footer>
  );
}
