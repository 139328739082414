import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pending: false,
  showCallModal: false,
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setPending: (store, action) => {
      return {
        ...store,
        pending: action.payload,
      };
    },
    setShowCallModal: (store, action) => {
      return {
        ...store,
        showCallModal: action.payload,
      };
    },
  },
});

export const { setPending, setShowCallModal } = mainSlice.actions;
export default mainSlice.reducer;
