import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
let sliderWith;
export default function Slider() {
  const [active, setActive] = useState(2);
  const [width, setWidth] = useState(null);
  const data = [
    {
      title: "Искусство Ковки",
      description1: "Элегантность и Прочность в Каждой Детали",
      description2:
        "Создайте неповторимый интерьер с помощью наших кованых изделий, где традиция встречается с современностью, а красота с функциональностью.",
      image: require("../../../../assets/slider/slider1.jpg"),
    },
    {
      title: "Кованые Изделия",
      description1: "От Классики до Современного Дизайна",
      description2:
        "Изысканные формы, прочные материалы и неповторимый стиль – вот что делает наши кованые изделия уникальными.",
      image: require("../../../../assets/slider/slider2.jpg"),
    },
    {
      title: "Мастерство Ковки",
      description1: "Изысканные Решения для Вашего Дома и Сада",
      description2:
        "Погрузитесь в мир кованых изделий, где каждая деталь рассказывает свою историю мастерства и красоты.",
      image: require("../../../../assets/slider/slider3.jpg"),
    },
  ];

  const slideRef = useRef();
  useEffect(() => {
    if (slideRef.current) {
      const currentWidth = slideRef.current.clientWidth;
      setWidth(currentWidth);
    }
  }, []);

  return (
    <section className={styles.hero}>
      <div className="wrapper">
        <ul className={styles.hero__list}>
          {data.map((item, index) => (
            <li
              // onMouseEnter={() => {
              //   if (index !== active) setActive(index);
              // }}
              ref={slideRef}
              onClick={() => {
                // console.log("slideRef", slideRef.current.clientWidth);
                if (index !== active) setActive(index);
              }}
              className={`${styles.hero__item}  ${
                active === index && styles._active
              }`}
            >
              <div
                className={styles.hero__item_inner}
                // style={{ width: (window.innerWidth / 100) * 78 + "px" }}
                style={{
                  width: (window.innerWidth > 1900 ? 1600 : width) + "px",
                  // : (window.innerWidth / 100) * 80) + "px",
                }}
              >
                <div className={styles.hero__content}>
                  <h3 className={styles.hero__title}>{item.title}</h3>
                  <div className={styles.hero__nav}>
                    <button
                      onClick={() => {
                        setActive(active - 1);
                      }}
                      className={styles.hero__nav_btn}
                      type="button"
                    >
                      <svg width="100px" height="40px">
                        <path d="M86 25H13.828l11.5 11.5L24 38 10 24l14-14 1.328 1.5-11.5 11.5H86v2z"></path>
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        setActive(active + 1);
                      }}
                      className={styles.hero__nav_btn}
                      type="button"
                    >
                      <svg width="100px" height="40px">
                        <path d="M10 25h72.172l-11.5 11.5L72 38l14-14-14-14-1.328 1.5 11.5 11.5H10v2z"></path>
                      </svg>
                    </button>
                  </div>
                  <p className={styles.hero__desc}>
                    <span style={{ marginRight: "4.5rem" }}>
                      {item.description1}
                    </span>
                    <span>{item.description2}</span>
                  </p>
                </div>
                <div className={styles.hero__img_wrap}>
                  <picture>
                    <source
                      media="(min-width: 1200px)"
                      data-srcset={`${item.image} 1x,  2x`}
                      srcset={`${item.image} 1x,  2x`}
                    />
                    <source
                      // media="(min-width: 768px)"
                      data-srcset={`${item.image} 1x, ${item.image} 2x`}
                      srcset={`${item.image} 1x, ${item.image} 2x`}
                    />
                    <img
                      className={styles.hero__img}
                      data-src={item.image}
                      data-srcset={`${item.image}  2x`}
                    />
                  </picture>
                  <div className={styles.hero__ressort}>
                    <svg
                      className={styles.hero__ressort_item}
                      preserveAspectRatio="xMinYMid meet"
                      x="0"
                      y="0"
                      width="817"
                      height="817"
                      viewBox="0 0 817 817"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        r="408"
                        transform="matrix(-1 0 0 1 408.5 408.5)"
                        stroke="#222433"
                      />
                    </svg>
                    <svg
                      className={styles.hero__ressort_item}
                      preserveAspectRatio="xMinYMid meet"
                      x="0"
                      y="0"
                      width="650"
                      height="817"
                      viewBox="0 0 650 817"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.5 408.5C0.5 633.946 145.665 816.5 324.509 816.5C503.353 816.5 648.519 633.946 648.519 408.5C648.519 183.055 503.353 0.500244 324.509 0.500244C145.665 0.500244 0.5 183.055 0.5 408.5Z"
                        stroke="#222433"
                      />
                    </svg>
                    <svg
                      className={styles.hero__ressort_item}
                      preserveAspectRatio="xMinYMid meet"
                      x="0"
                      y="0"
                      width="487"
                      height="817"
                      viewBox="0 0 487 817"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.500732 408.5C0.500732 521.23 27.7253 623.263 71.7152 697.097C115.711 770.942 176.421 816.5 243.383 816.5C310.344 816.5 371.054 770.942 415.05 697.097C459.04 623.263 486.265 521.23 486.265 408.5C486.265 295.77 459.04 193.737 415.05 119.903C371.054 46.0584 310.344 0.500244 243.383 0.500244C176.421 0.500244 115.711 46.0584 71.7152 119.903C27.7253 193.737 0.500732 295.77 0.500732 408.5Z"
                        stroke="#222433"
                      />
                    </svg>
                    <svg
                      className={styles.hero__ressort_item}
                      preserveAspectRatio="xMinYMid meet"
                      x="0"
                      y="0"
                      width="325"
                      height="817"
                      viewBox="0 0 325 817"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.500488 408.5C0.500488 521.257 18.6548 623.317 47.9885 697.169C62.6559 734.096 80.1085 763.946 99.4628 784.556C118.816 805.165 140.036 816.5 162.255 816.5C184.475 816.5 205.694 805.165 225.047 784.556C244.402 763.946 261.854 734.096 276.522 697.169C305.855 623.317 324.01 521.257 324.01 408.5C324.01 295.743 305.855 193.683 276.522 119.832C261.854 82.9043 244.402 53.0543 225.047 32.4445C205.694 11.8355 184.475 0.500244 162.255 0.500244C140.036 0.500244 118.816 11.8355 99.4628 32.4445C80.1085 53.0543 62.6559 82.9043 47.9885 119.832C18.6548 193.683 0.500488 295.743 0.500488 408.5Z"
                        stroke="#222433"
                      />
                    </svg>
                    <svg
                      className={styles.hero__ressort_item}
                      preserveAspectRatio="xMinYMid meet"
                      x="0"
                      y="0"
                      width="163"
                      height="817"
                      viewBox="0 0 163 817"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.501221 408.5C0.501221 521.282 9.58022 623.373 24.2533 697.256C31.5906 734.202 40.3219 764.068 50.0027 784.686C54.8438 794.996 59.91 802.966 65.1374 808.353C70.364 813.738 75.711 816.5 81.1285 816.5C86.5461 816.5 91.893 813.738 97.1197 808.353C102.347 802.966 107.413 794.996 112.254 784.686C121.935 764.068 130.666 734.202 138.004 697.256C152.677 623.373 161.756 521.282 161.756 408.5C161.756 295.719 152.677 193.628 138.004 119.744C130.666 82.7988 121.935 52.9325 112.254 32.3147C107.413 22.0045 102.347 14.0338 97.1197 8.64774C91.893 3.26231 86.5461 0.500244 81.1285 0.500244C75.711 0.500244 70.364 3.26231 65.1374 8.64774C59.91 14.0338 54.8438 22.0045 50.0027 32.3147C40.3219 52.9325 31.5906 82.7988 24.2533 119.744C9.58022 193.628 0.501221 295.719 0.501221 408.5Z"
                        stroke="#222433"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
