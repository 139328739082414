import React from "react";
import styles from "./style.module.scss";

export default function OurAdvantages() {
  return (
    <div className={`wrapper ${styles.main}`}>
      <div className={styles.titleBlock}>
        <h2>
          <span>
            Наши <strong>3</strong> Неоспоримых{" "}
          </span>
          <span>Преимущества</span>
          <span>Кованых Изделий:</span>
        </h2>
      </div>
      {window.innerWidth > 1100 && <div className="line" />}
      <div className={styles.row}>
        <div className={styles.block}>
          <div className={styles.image}>
            <svg
              width="130"
              height="130"
              viewBox="0 0 130 130"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_237_37872)">
                <path
                  d="M63.3264 33.3606C63.2154 45.9402 55.1568 57.3935 43.2752 61.6132C31.4253 65.8328 17.7511 62.0098 9.83528 52.2221C1.91948 42.4344 0.920086 28.4112 7.45578 17.6083C13.9915 6.80537 26.8725 1.23734 39.1824 3.71202C53.2532 6.5357 63.2154 19.1629 63.3264 33.3765C63.3423 35.4228 66.4991 35.1373 66.4832 33.1068C66.3722 19.3215 57.568 6.58329 44.4967 1.93533C31.4253 -2.71263 16.371 1.3008 7.50337 12.0879C-1.36423 22.8749 -2.4588 38.4686 4.72729 50.4454C11.9134 62.4222 26.2697 68.6565 39.8804 65.8328C55.3789 62.6284 66.3722 48.8115 66.4991 33.0909C66.515 31.0445 63.3582 31.3459 63.3423 33.3606H63.3264Z"
                  fill="black"
                />
                <path
                  d="M126.843 33.3606C126.732 45.9402 118.674 57.3935 106.792 61.6132C94.942 65.8328 81.2678 62.0098 73.352 52.2221C65.4362 42.4185 64.4368 28.4112 70.9566 17.6083C77.4765 6.80537 90.3892 1.23734 102.699 3.71202C116.77 6.53569 126.732 19.1629 126.843 33.3765C126.859 35.4228 130.016 35.1373 130 33.1068C129.889 19.3215 121.085 6.58328 108.013 1.93532C94.942 -2.71263 79.8718 1.31665 71.0042 12.1196C62.1366 22.9225 61.0421 38.5003 68.2281 50.4613C75.4142 62.4222 89.7705 68.6724 103.381 65.8487C118.88 62.6443 129.873 48.8273 130 33.1068C130.016 31.0604 126.859 31.3618 126.843 33.3765V33.3606Z"
                  fill="black"
                />
                <path
                  d="M63.3264 96.8774C63.2154 109.457 55.1568 120.91 43.2752 125.13C31.4253 129.35 17.7511 125.527 9.83527 115.739C1.91946 105.935 0.920074 91.9281 7.4399 81.1251C13.9597 70.3222 26.8566 64.7542 39.1666 67.2289C53.2373 70.0525 63.1995 82.6798 63.3106 96.8933C63.3264 98.9397 66.4832 98.6541 66.4674 96.6236C66.3563 82.8384 57.5522 70.1001 44.4808 65.4522C31.4094 60.8042 16.3392 64.8335 7.47163 75.6364C-1.39597 86.4394 -2.49054 102.017 4.69555 113.978C11.8816 125.939 26.238 132.189 39.8487 129.366C55.3472 126.161 66.3405 112.344 66.4674 96.6236C66.4832 94.5772 63.3264 94.8787 63.3106 96.8933L63.3264 96.8774Z"
                  fill="black"
                />
                <path
                  d="M126.843 96.8774C126.732 109.457 118.674 120.91 106.792 125.13C94.942 129.35 81.2678 125.527 73.352 115.739C65.4362 105.951 64.4368 91.928 70.9566 81.1251C77.4606 70.3539 90.3734 64.7541 102.683 67.2288C116.754 70.0525 126.716 82.6797 126.827 96.8932C126.843 98.9396 130 98.6541 129.984 96.6235C129.873 82.8383 121.069 70.1001 107.998 65.4521C94.8627 60.7883 79.856 64.8334 70.9884 75.6364C62.1208 86.4393 61.0262 102.017 68.2123 113.978C75.3984 125.939 89.7547 132.189 103.365 129.365C118.864 126.161 129.857 112.344 129.984 96.6235C130 94.5772 126.843 94.8786 126.827 96.8932L126.843 96.8774Z"
                  fill="black"
                />
                <path
                  d="M95.0848 65.119C94.9738 77.6986 86.9152 89.152 75.0336 93.3716C63.1837 97.5912 49.5095 93.7682 41.5937 83.9805C33.6779 74.1928 32.6785 60.1696 39.1983 49.3667C45.7182 38.5638 58.615 32.9958 70.925 35.4704C84.9958 38.2941 94.9579 50.9213 95.069 65.1349C95.0848 67.1812 98.2416 66.8957 98.2258 64.8652C98.1147 51.08 89.3106 38.3417 76.2392 33.6937C63.1678 29.0458 48.0977 33.0751 39.2301 43.878C30.3625 54.6809 29.2679 70.2587 36.454 82.2197C43.6401 94.1806 57.9964 100.431 71.6071 97.6071C87.1056 94.4027 98.0989 80.5858 98.2258 64.8652C98.2416 62.8188 95.0848 63.1202 95.069 65.1349L95.0848 65.119Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_237_37872">
                  <rect width="130" height="130" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span className={styles.text}>Прочность и долговечность</span>
        </div>

        <div className={styles.block}>
          <div className={styles.image}>
            <svg
              width="130"
              height="130"
              viewBox="0 0 130 130"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_237_37879)">
                <path
                  d="M126.826 65.1031C126.715 90.7921 110.306 114.307 86.1377 123.114C61.7785 131.999 33.6267 124.351 17.1545 104.311C0.603015 84.1755 -1.47584 55.0909 12.0447 32.7816C25.4858 10.6469 52.1142 -0.84098 77.4414 4.34759C102.769 9.53617 122.288 30.2746 126.112 55.5035C126.588 58.6769 126.826 61.8821 126.826 65.1031C126.826 67.15 130 66.8644 129.984 64.8334C129.873 37.7481 112.465 12.8207 86.8677 3.71291C61.2707 -5.39487 31.9446 2.6498 14.6472 23.6738C-2.65016 44.6979 -4.8401 75.3692 9.31517 98.7416C23.4704 122.114 51.4953 134.237 78.1238 128.667C104.752 123.098 125.065 101.598 129.207 75.1153C129.73 71.7039 129.968 68.2607 129.984 64.8175C129.984 62.7706 126.826 63.0721 126.826 65.0872V65.1031Z"
                  fill="black"
                />
                <path
                  d="M102.023 65.1031C101.912 80.4943 92.0886 94.5526 77.6001 99.8206C63.1115 105.088 46.1316 100.535 36.2927 88.5231C26.4538 76.5116 25.1684 59.2005 33.1665 45.8403C41.1645 32.4801 57.0813 25.5938 72.2522 28.6244C89.5495 32.0676 101.896 47.6175 102.023 65.119C102.023 67.1659 105.197 66.8802 105.181 64.8492C105.07 48.0617 94.2627 32.5753 78.3301 27.0377C62.3974 21.5 44.4336 26.4823 33.7854 39.5093C23.1372 52.5363 21.8042 71.4976 30.5639 85.9368C39.3237 100.376 56.7163 107.849 73.1885 104.374C91.803 100.439 105.038 83.7629 105.181 64.8492C105.181 62.8024 102.039 63.1038 102.023 65.119V65.1031Z"
                  fill="black"
                />
                <path
                  d="M73.4106 47.6651C73.4106 52.3459 69.6179 56.1382 64.9365 56.1382C60.2551 56.1382 56.4624 52.3459 56.4624 47.6651C56.4624 42.9843 60.2551 39.192 64.9365 39.192C69.6179 39.192 73.4106 42.9843 73.4106 47.6651Z"
                  fill="black"
                />
                <path
                  d="M73.4106 82.2714C73.4106 86.9523 69.6179 90.7445 64.9365 90.7445C60.2551 90.7445 56.4624 86.9523 56.4624 82.2714C56.4624 77.5906 60.2551 73.7983 64.9365 73.7983C69.6179 73.7983 73.4106 77.5906 73.4106 82.2714Z"
                  fill="black"
                />
                <path
                  d="M82.2498 73.4493C77.5684 73.4493 73.7756 69.6571 73.7756 64.9763C73.7756 60.2954 77.5684 56.5032 82.2498 56.5032C86.9312 56.5032 90.7239 60.2954 90.7239 64.9763C90.7239 69.6571 86.9312 73.4493 82.2498 73.4493Z"
                  fill="black"
                />
                <path
                  d="M47.6233 73.4493C42.9419 73.4493 39.1492 69.6571 39.1492 64.9763C39.1492 60.2954 42.9419 56.5032 47.6233 56.5032C52.3047 56.5032 56.0974 60.2954 56.0974 64.9763C56.0974 69.6571 52.3047 73.4493 47.6233 73.4493Z"
                  fill="black"
                />
                <path
                  d="M67.9041 51.0924H61.9849C62.3022 63.7227 52.1619 62.4692 52.1619 62.4692V67.4515C52.1619 67.4515 62.3022 66.198 61.9849 78.8283H67.9041C67.5867 66.198 77.7271 67.4515 77.7271 67.4515V62.4692C77.7271 62.4692 67.5867 63.7227 67.9041 51.0924Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_237_37879">
                  <rect width="130" height="130" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span className={styles.text}>Уникальный дизайн</span>
        </div>

        <div className={styles.block}>
          <div className={styles.image}>
            <svg
              width="130"
              height="130"
              viewBox="0 0 130 130"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_237_37868)">
                <path
                  d="M126.826 65.1031C126.715 90.7921 110.306 114.307 86.1377 123.114C61.969 131.92 33.6267 124.351 17.1545 104.311C0.603015 84.1755 -1.47584 55.0909 12.0447 32.7816C25.5652 10.4723 52.1142 -0.84098 77.4414 4.34759C102.419 9.45683 122.288 30.2746 126.112 55.5035C126.588 58.6769 126.826 61.8821 126.826 65.1031C126.826 67.15 130 66.8644 129.984 64.8334C129.873 37.7481 112.465 12.8207 86.8677 3.71291C61.2707 -5.39487 31.9446 2.6498 14.6472 23.6738C-2.65016 44.6979 -4.8401 75.3692 9.31517 98.7416C23.4704 122.114 51.4953 134.237 78.1238 128.667C104.752 123.098 125.065 101.598 129.207 75.1153C129.73 71.7039 129.968 68.2607 129.984 64.8175C129.984 62.7706 126.826 63.0721 126.826 65.0872V65.1031Z"
                  fill="black"
                />
                <path
                  d="M102.023 65.1031C101.912 80.4943 92.0886 94.5526 77.6001 99.8205C63.1115 105.088 46.1316 100.535 36.2927 88.5231C26.4538 76.5116 25.1684 59.2005 33.1665 45.8403C41.1645 32.4801 57.0813 25.5938 72.2522 28.6244C89.5495 32.0676 101.896 47.6175 102.023 65.119C102.023 67.1658 105.197 66.8802 105.181 64.8492C105.07 48.0617 94.2627 32.5753 78.3301 27.0377C62.3974 21.5 44.4336 26.4823 33.7854 39.5093C23.1372 52.5363 21.8042 71.4976 30.5639 85.9367C39.292 100.328 56.7163 107.849 73.1884 104.374C91.8029 100.439 105.038 83.7629 105.181 64.8492C105.181 62.8024 102.039 63.1038 102.023 65.119V65.1031Z"
                  fill="black"
                />
                <path
                  d="M80.7262 65.1031C80.631 71.688 76.394 77.6541 70.1733 79.8596C63.9526 82.0652 56.7004 80.0183 52.5902 74.8138C48.4801 69.6094 48.0199 62.374 51.4477 56.757C54.8754 51.14 61.5563 48.2363 67.9833 49.4898C75.4735 50.9496 80.631 57.5979 80.7262 65.119C80.758 67.1658 83.9159 66.8802 83.8842 64.8492C83.7731 57.0426 78.8061 49.823 71.411 47.1732C64.016 44.5233 55.2721 46.8082 50.2257 53.0282C45.1794 59.2481 44.6557 67.9909 48.7182 74.798C52.7807 81.605 60.9374 85.1434 68.6339 83.5725C77.5841 81.7478 83.7731 73.8935 83.8842 64.8492C83.9159 62.8024 80.758 63.1038 80.7262 65.119V65.1031Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_237_37868">
                  <rect width="130" height="130" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span className={styles.text}>Эстетика и элегантность</span>
        </div>
      </div>
      {window.innerWidth > 1100 && <div className="line" />}
    </div>
  );
}
