import React from "react";
import styles from "./style.module.scss";
import CategoryItem from "./components/CatgeoryItem";
import GetConsultation from "./components/GetConsultation/Index";
const categories = [
  {
    title: "Ворота и калитки",
    value: "gates",
    img: require("./assets/vorota.jpg"),
  },
  {
    title: "Лестницы и перила",
    value: "railings",
    img: require("./assets/lesnici.jpg"),
  },
  {
    title: "Козырьки",
    value: "visors",
    img: require("./assets/kazirki.jpg"),
  },
  {
    title: "Навесы",
    value: "awnings",
    img: require("./assets/naves.webp"),
  },
  {
    title: "Кованые балконы",
    value: "balconies",
    img: require("./assets/balkoni.jpg"),
  },
  {
    title: "Качели",
    value: "swing",
    img: require("./assets/kacheli.jpg"),
  },
  {
    title: "Беседки",
    value: "gazebos",
    img: require("./assets/besedki.jpeg"),
  },
  {
    title: "Мангалы",
    value: "barbecues",
    img: require("./assets/mangali.jpg"),
  },
  {
    title: "Предметы интерьера",
    value: "interior",
    img: require("./assets/interier.jpg"),
  },
  {
    title: "Ограды и ограждения",
    value: "fences",
    img: require("./assets/ogradi.jpeg"),
  },
];
export default function Categories() {
  return (
    <div className="wrapper">
      <div className={styles.title}>
        <span>Категории</span>
        <span>Кованых</span>
        <span>Изделий</span>
      </div>
      <div className={styles.main}>
        {categories.map((item, index) => (
          <CategoryItem data={item} key={index} />
        ))}
        <GetConsultation />
      </div>
    </div>
  );
}
