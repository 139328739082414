import React, { useEffect, useRef, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { setShowCallModal } from "../../store/slices/MainSlice";
import { toast } from "react-toastify";

export default function OrderCallModal() {
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const showCallModal = useSelector(({ main }) => main.showCallModal);
  const dispatch = useDispatch();
  const mainRef = useRef();
  useEffect(() => {
    if (mainRef.current) {
      function handleClickOutside(event) {
        if (!mainRef.current?.contains(event.target)) {
          dispatch(setShowCallModal(false));
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [mainRef.current]);
  useEffect(() => {
    if (showCallModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showCallModal]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    // setNameError(!formDataObject.name);
    setPhoneError(!formDataObject.phone);

    if (!formDataObject.phone) {
      return false;
    }
    dispatch(setShowCallModal(false));

    try {
      // Отправляем данные формы на сервер
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/send-mail`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDataObject),
        }
      );

      if (response.ok) {
        toast(
          "Спасибо за заявку!  Мы получили вашу заявку и свяжемся с вами в ближайшее время.",
          {
            type: "info",
          }
        );
      } else {
        const errorData = await response.json();
        console.log(`Failed to send email: ${errorData.error}`);
        toast(
          "Что-то пошло не так при отправке заявки. Пожалуйста, попробуйте еще раз позже или свяжитесь с нами напрямую.",
          {
            type: "error",
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  if (!showCallModal) return null;
  return (
    <div className={styles.bg}>
      <div
        onClick={() => dispatch(setShowCallModal(false))}
        className={styles.close}
      >
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#fff"
            d="M0 1.412L1.412 0 24 22.588 22.59 24 0 1.412z"
          ></path>
          <path
            fill="#fff"
            d="M1.412 24L0 22.588 22.588 0 24 1.412 1.412 24z"
          ></path>
        </svg>
      </div>
      <div ref={mainRef} className={styles.main}>
        <h2>Заказать звонок</h2>
        <p>Отправьте заявку, и мы свяжемся с вами в ближайшее время</p>
        <form onSubmit={handleSubmit}>
          <input
            className={nameError && styles.inputError}
            type="text"
            name="name"
            placeholder="Ваше имя"
          />
          <input
            className={phoneError && styles.inputError}
            type="phone"
            inputmode="numeric"
            name="phone"
            placeholder="Телефон"
          />
          <button type="submit">Отправить заявку</button>
        </form>
      </div>
    </div>
  );
}
