import React from "react";
import styles from "./style.module.scss";
import Slider from "./components/Slider";
import OurLatestWorks from "./components/OurLatestWorks/index";
import ExclusivelyHandmade from "./components/ExclusivelyHandmade";
import Categories from "./components/Categories";
import OurAdvantages from "./components/OurAdvantages";
import AboutUs from "./components/AboutUs";

export default function Home() {
  return (
    <div className={styles.container}>
      {window.innerWidth <= 768 && <Categories />}
      <Slider />
      <OurLatestWorks />
      {/* <ExclusivelyHandmade /> */}
      {window.innerWidth > 768 && <Categories />}

      <OurAdvantages />
      <AboutUs />
    </div>
  );
}
