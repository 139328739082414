import "./App.css";
import { store } from "./store/store";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import useScrollToTop from "./helpers/useScrollToTop";
import { Provider } from "react-redux";
import { AboutUs, Catalog, Contacts, Home } from "./screens";
import Header from "./components/Header";
import Footer from "./components/Footer";
import OrderCallModal from "./components/OrderCallModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  useScrollToTop();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="light"
        icon={false}
        style={{ fontSize: "15px" }}
        progressStyle={{
          backgroundColor: "#d10000",
          zIndex: 999999999999999,
        }}
      />
      <Header />
      <Outlet />
      <Footer />
      <OrderCallModal />
    </>
  );
};

function App() {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/catalog",
          element: <Catalog />,
        },
        {
          path: "/contacts",
          element: <Contacts />,
        },
        {
          path: "/aboutUs",
          element: <AboutUs />,
        },
      ],
    },
  ]);

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
