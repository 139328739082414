import React from "react";
import styles from "./style.module.scss";
import { Link, NavLink } from "react-router-dom";

export default function OurLatestWorks() {
  return (
    <div className="wrapper">
      <div className={styles.header}>
        <h4>Наши последние работы</h4>
        <p>
          "Откройте для себя мир кованых изделий, где искусство встречается с
          функциональностью. Наши уникальные предметы – это не только элементы
          декора, но и символы мастерства и элегантности. Погрузитесь в эстетику
          ковки и обогатите свое пространство неповторимым стилем."
        </p>
      </div>
      <div className="line" />
      <div className={styles.images}>
        <img src={require("./assets/1.webp")} />
        <img src={require("./assets/2.webp")} />
        <img src={require("./assets/3.webp")} />
        <img src={require("./assets/4.webp")} />
        <img src={require("./assets/5.webp")} />
        <img src={require("./assets/6.webp")} />
        <img src={require("./assets/7.webp")} />
        <img src={require("./assets/8.webp")} />
      </div>
      <Link to={"/catalog"} className={styles.callbackBtn}>
        <span>показать ещё</span>
      </Link>
      <div className="line" />
    </div>
  );
}
