import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { ImageGallery } from "react-image-grid-gallery";
import Loader from "../../components/Loader";

const categories = {
  gates: "Ворота и калитки",
  railings: "Лестницы и перила",
  visors: "Козырьки",
  awnings: "Навесы",
  balconies: "Кованые балконы",
  swing: "Качели",
  gazebos: "Беседки",
  barbecues: "Мангалы",
  interior: "Предметы интерьера",
  fences: "Ограды и ограждения",
};
export default function Catalog() {
  const [images, setImages] = useState([]);
  const [pending, setPending] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    try {
      setPending(true);
      axios
        .get(
          process.env.REACT_APP_SERVER_URL +
            `/api/images?dir=${searchParams.get("dir") || "gates"}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((e) => {
          setImages(e.data);
        })
        .catch((e) => {
          console.warn("axios error", e);
        })
        .finally(() => {
          setPending(false);
        });
    } catch (error) {
      console.warn("request error", error);
    }
  }, [searchParams]);

  return (
    <div className={`wrapper ${styles.container}`}>
      <div className={styles.titleBg}>
        <div className={styles.titleBlock}>
          <h1>Категории Кованых Изделий</h1>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.nav}>
          {Object.keys(categories || {}).map((key) => (
            <span
              onClick={() => {
                searchParams.set("dir", key);
                setSearchParams(searchParams);
              }}
              className={searchParams.get("dir") == key && styles.active}
            >
              {categories[key] || "Ворота и калитки"}
            </span>
          ))}
        </div>
        <div className={styles.content}>
          <h1>{categories[searchParams.get("dir")]}</h1>
          {pending ? (
            <Loader />
          ) : images.length ? (
            <>
              <ImageGallery
                imagesInfoArray={images.map((url) => ({
                  src: process.env.REACT_APP_SERVER_URL + url,
                  alt: searchParams.get("dir"),
                }))}
                columnCount={"auto"}
                columnWidth={images.length < 6 ? 350 : 250}
                gapSize={10}
              />
            </>
          ) : (
            <div>
              <span>Тут пока что нет изображения</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
