import React from "react";
import styles from "./styles.module.scss";

export default function Loader() {
  return (
    <div className={styles.profileMainLoader}>
      <div className={styles.loader}>
        <svg className={styles.circularLoader} viewBox="25 25 50 50">
          <circle
            className={styles.loaderPath}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke="rgb(34, 36, 50)"
            stroke-width="3"
          />
        </svg>
      </div>
    </div>
  );
}
